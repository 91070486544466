<template>
    <button :class="{ relative }" type="button" class="close-lg" @click.prevent.stop="$emit('close', $event)">
        <svg
            class="pointer-events-none"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.40033 9.40039L22.5997 22.5997"
                stroke="#323030"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.40033 22.5996L22.5997 9.40028"
                stroke="#323030"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </button>
</template>

<script>
export default {
    props: {
        relative: {
            type: Boolean,
            default: false
        }
    },
    name: "CloseButtonLg"
}
</script>
<style lang="sass" scoped>
.close-lg
    position: absolute
    top: 10px
    right: 10px
    &.relative
        position: relative
        top: 0
        right: 0
    svg
        path
            transition: .2s
    &:hover
        svg
            path
                stroke: #3965FF !important
</style>
